<template>
  <div class="all-box">
    <!-- <div class="card">
      <h1>ChatGPT 演示</h1>
      <p>
        <a-tag color="geekblue"
          >via GPT-3.5 对话模式</a-tag
        >
      </p>
      <p></p> -->
    <!-- <a-alert type="warning" show-icon>
        <div slot="message">
          因需要访问国外网络，如果出现多次异常，请刷新重试
        </div>
      </a-alert> -->
    <!-- </div> -->
    <!-- 
      <a-button @click="clean">清除聊天记录</a-button>
     -->
    <div class="chat_1">
      <beautiful-chat
        :participants="participants"
        :titleImageUrl="titleImageUrl"
        :onMessageWasSent="onMessageWasSent"
        :messageList="messageList"
        :newMessagesCount="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :icons="icons"
        :open="openChat"
        :showEmoji="false"
        :showFile="false"
        :showEdition="true"
        :showDeletion="true"
        :showTypingIndicator="showTypingIndicator"
        :showLauncher="false"
        :showCloseButton="false"
        :colors="colors"
        :alwaysScrollToBottom="alwaysScrollToBottom"
        :disableUserListToggle="true"
        :showHeader="false"
        :messageStyling="messageStyling"
        @onType="handleOnType"
        @edit="editMessage"
      />
    </div>
    <div class="background"></div>
  </div>
</template>
<script>
import axios from "axios";
import _ from "lodash";
export default {
  data() {
    return {
      participants: [
        {
          id: "user1",
          name: "chatgpt",
          imageUrl:
            "//afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images.png",
        },
        // {
        //   id: "user2",
        //   name: "Support",
        //   imageUrl:
        //     "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4",
        // },
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl:
        "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      messageList: [
        {
          type: "text",
          author: `user1`,
          data: {
            text: `你好。`,
          },
        },
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: "#4e8cff",
          text: "#ffffff",
        },
        launcher: {
          bg: "#4e8cff",
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: "#4e8cff",
          text: "#ffffff",
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: false, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    };
  },
  components: {},
  mounted() {},
  methods: {
    clean() {
      this.messageList = [];
      this.newMessagesCount = 0;
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "user1",
          type: "text",
          data: { text },
        });
      }
    },
    getBody() {
      // let s = "";
      // this.messageList.forEach((item) => {
      //   if (item.author === "me") {
      //     // s += `\nQ:${item.data.text}`;
      //     s = `\nQ:${item.data.text}`;
      //   } else {
      //     // s += `\nA:${item.data.text}`;
      //   }
      // });
      // return s;
      let s = [];
      this.messageList.forEach((item) => {
        if (item.author === "me") {
          let obj = {
            role: "user",
            content: item.data.text,
          };
          s.push(obj);
        } else {
          let obj = {
            role: "assistant",
            content: item.data.text,
          };
          s.push(obj);
        }
      });
      console.log("getbody:", s);
      return s;
    },
    onMessageWasSent(message) {
      // called when the user sends a message
      console.log(message);
      this.messageList = [...this.messageList, message];
      if (message.author === "me") {
        let body = this.getBody();
        axios
          .post(
            "//api-dev.afanticar.com/chatgpt/chat/beta",
            {
              // body,
              // model: "text-davinci-003",
              // maxTokens: 4000,
              // temperature: 0.6,
              // topP: 1.0,
              // frequencyPenalty: 1.0,
              // presencePenalty: 0.0,
              // n: 1,
              // stops: null,
              // token: "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",
              bodies: body,
              model: "gpt-3.5-turbo",
              maxTokens: 4000,
              temperature: 0.6,
              topP: 1.0,
              frequencyPenalty: 1.0,
              presencePenalty: 0.0,
              n: 1,
              stops: null,
              // token: "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",
            }
          )
          .then((res) => {
            this.showTypingIndicator = "";
            let text = _.get(res, "data.data[0].message.content");
            if (text) {
              console.log(text);
              // text = text.replace("\nA:", "");
              // text = text.replace(/^？/, "");
              this.sendMessage(text);
            } else {
              this.sendMessage(
                "哎呀~系统异常，请重试。（ps.问题不能太长喔；如果出现多次异常请刷新页面）"
              );
            }
          })
          .catch((res) => {
            this.sendMessage("哎呀呀~网络异常");
          });
        this.showTypingIndicator = "user1";
      }
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      // console.log("Emit typing event");
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
  },
};
</script>
<style lang="scss" scoped>
.ml10 {
  margin-left: 10px;
}
.pdt12 {
  padding-top: 12px;
}
.tag__s {
  font-size: 16px;
  padding: 2px 8px;
}
.word__des {
  padding: 8px 0;
  display: flex;
  .lebel_tips {
    width: 86px;
  }
  .right_box {
    flex: 1;
  }
}
.color1 {
  color: black;
}
.flex-be {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .select-exe {
    flex: 7;
  }
  .down {
    flex: 3;
    text-align: right;
  }
}
.description-file {
  background-color: rgba($color: #bbb, $alpha: 0.3);
  padding: 4px 8px;
  margin: 4px 0;
}
.sound_tips {
  font-size: 16px;
  margin-right: 3px;
  color: #333;
}
.color2 {
  color: rgb(243, 159, 5);
}
.upload-file {
  padding: 6px;
}
.flex-b {
  display: flex;
  align-items: center;
  h3 {
    margin-left: 12px;
    margin-bottom: 0;
  }
}
.all-box {
  width: 500px;
  margin: 0 auto;
}
.card {
  width: 540px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;
}
.footer {
  display: flex;
  justify-content: center;
}
.mgn-bt {
  margin-bottom: 10px;
  text-align: center;
}
.desbox {
  margin-bottom: 12px;
  &-msg {
    display: flex;
    &-name {
      color: #888;
      font-weight: bold;
    }
    &-remark {
      color: #aaa;
      // text-indent: 2em;
      text-align: justify;
      flex: 1;
    }
  }
  &-time {
    color: #888;
  }
}
.remark-box {
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0;

  &-time {
    color: #888;
  }
  &-msg {
    display: flex;
    &-name {
      color: #888;
      font-weight: bold;
    }
    &-remark {
      flex: 1;
      color: #aaa;
      text-align: justify;
    }
  }
}
.appraisal {
  border-bottom: none;
}

@media screen and (max-width: 500px) {
  .all-box {
    width: 90%;
    margin: 24px 5%;
    padding-bottom: 24px;
  }
  .card {
    width: 100%;
  }
  .btndown {
    padding: 0 15px;
  }
}
</style>

<style lang="scss">
.sc-message p {
  margin-bottom: 0px !important;
}
.chat_1 .sc-chat-window {
  width: 880px;
  max-height: 100%;
  height: calc(100% - 50px);
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 99;
}
.chat_1 .sc-message {
  width: 840px;
}

.chat_1 .sc-user-input--text {
  width: 800px;
}

.chat_1 .sc-message--text-content {
  // line-height: 1.6;
  letter-spacing: 0.1em;
  white-space:pre-line;
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #bbb;
}
</style>